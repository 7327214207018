import React, { useState } from 'react';
import image1 from './assets/ta_slider1.svg';
import image2 from './assets/ta_slidertwo.svg';
import image3 from './assets/ta_slider3.svg';
import image4 from './assets/ta_slider4.svg';
import rightgrey from './assets/costarrowright.svg';
import leftgrey from './assets/costarrowleft.svg';

const FoSliderBootstrap = () => {
  const images = [image1, image2, image3, image4]; // Array of slide images
  const [currentIndex, setCurrentIndex] = useState(0); // Current slide index

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="container-fluid" style={{ height: '895px' }}>
      {/* Arrows and Title Section */}
      <div className="d-flex align-items-center justify-content-center pt-5">
        <button onClick={prevSlide} className="btn me-5">
          <img src={leftgrey} alt="left-arrow" />
        </button>
        <h2 className="text-black fw-bold" style={{ fontSize: '34px' }}>T&A and Follow-ups:</h2>
        <button onClick={nextSlide} className="btn ms-5">
          <img src={rightgrey} alt="right-arrow" />
        </button>
      </div>

      {/* Conditional Layout: Single or Two Columns */}
      {currentIndex === 0 ? (
        // Single column layout for the first slide
        <div className="mt-4 mx-auto" style={{ maxWidth: '89%' }}>
          <div className="d-flex justify-content-center">
            <img src={images[currentIndex]} className="img-fluid mt-4" style={{ maxWidth: '911px' }} />
          </div>
          <div className="text-center mt-4 mx-auto" style={{ fontSize: '22px', maxWidth: '995px' }}>
            FITTEX Time and Action (T&A) scheduler creates detailed timelines for every element of order management. With dynamic task calendars, real-time tracking, and a graphical dashboard, you can easily manage materials, processes, documents, and inspections ensuring seamless follow-ups and on-time execution.
          </div>
        </div>
      ) : (
        // Two column layout for the other slides
        <div className="row mt-4 mx-auto" style={{ maxWidth: '89%' }}>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={images[currentIndex]} className="img-fluid" />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="text-dark" style={{ fontSize: '22px', maxWidth: '597px' }}>
              FITTEX Time and Action (T&A) scheduler creates detailed timelines for every element of order management. With dynamic task calendars, real-time tracking, and a graphical dashboard, you can easily manage materials, processes, documents, and inspections ensuring seamless follow-ups and on-time execution.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FoSliderBootstrap;