import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import mms from './assets/mms_banner.svg'
import whitearrow from './assets/whiterrow.svg'
import fas from './assets/fas.svg'
import CRM from './assets/crmicon.svg'
import keywheel1 from './assets/key-wheel.svg'
import hashline from './assets/hashlinewider.svg'
import maindash from './assets/main_dashboard.svg'
import enquirynew from './assets/enquiry_newmangement.svg'
import ordermanagement from './assets/order_managemnent.svg'
import imgNew from './assets/fittexbanner.svg'
import greyline from './assets/greyline.svg'
import mainonebx from './assets/mainonebx.png'
import chartbg from './assets/chartbg.svg'
import fourpointdk from './assets/4pointdk.svg'
import gradientwave from './assets/gradient_shadows.svg'
import tara from './assets/tarasafe.svg'
import dc from './assets/dc.svg'
import CostingSlider from './costingLsider';
import FoSlider from './folowSlider';
import FoSliderBootstrap from './folowSlider';
import ShippmentsBootstrap from './billingShipmentsr';
import tshirt from "./assets/tshirt.png"; // Assuming 'tshirt' is the image path
function Mms() {
  return (
    <div>

<div
      className="d-flex flex-row w-100 align-items-center px-3 py-2 merchandising"
    >
      {/* Left Section with Logo and Text */}
      <div className="d-flex flex-row align-items-center w-50 ms-5 mt-n3">
        {/* Logo */}
        <div className="flex-shrink-0">
          <img src={tshirt} className="img-fluid" style={{ width: "151px", height: "118px", marginTopt: "-39px" }} alt="Logo" />  
        </div>
        {/* Text */}
        <div className="ms-2">
          <div className="fs-4 fw-bold text-black">Merchandising</div>
          <div className="fs-4" style={{ fontWeight: "500", color: "#171717", marginTop: "-11px" }}>
            Management System
          </div>
        </div>
      </div>

      {/* Right Section with Fixed Tabs */}
      <div className="d-flex w-50 justify-content-end align-items-center">
        <div
          className="position-fixed"
          style={{
            right: "1rem",
            top: "4rem",
            zIndex: 40,
            width: "300px", // Set a width to prevent layout issues
          }}
        >
          {/* <TabSection /> */}
        </div>
      </div>
    </div>







        
    <div className='gaintxt'>Gain total control of your </div>
    <div className='sourcetxt'>Sourcing & Buying Operations</div>
    <div className='fittext'>FIT- Merchandising Management provides seamless working environment for your <br></br> sourcing & Merchandising Operations.</div>
    <div className='requestbtnsec'>
        <div className='price' >See pricing <span><img src={whitearrow} className='whitearw'/></span></div>
        <div className='demo' >Request a Demo</div>

    </div>

    <img src={mms} className='mmsbanimage'/>
    
    <div class="container py-5">
    {/* <!-- Key Features Section --> */}
    <div class="row align-items-center mb-5">
      <div class="col-md-6">
      <img src={keywheel1} className=''/>
        <h3 class="mb-3">Key Features</h3>
        <p>
          Sed dignissim, metus nec fringilla accumsan, risus <br></br> sem sollicitudin lacus, ut interdum <br></br> tellus elit sed risus.
          Maecenas eget condimentum velit <br></br> nec fringilla accumsan.
        </p>

        <h3>Integrated Add-Ons</h3>
      </div>
      <div class="col-md-6 listing">

        <div className='row listitem'>
         <div class="col-md-6">
         <div class="feature-box">
          <ul class="list-unstyled">
            <li><span class="checkmark">✔</span> Product / Style Bank</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Sales Enquiry & Quotations</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Customer Order Management</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Sampling & Follow-ups</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Financial Accounting</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> MIS Query Reporting</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Dashboard & Analytics</li>
            {/* <li><span class="checkmark">✔</span> Time & Action Scheduler</li>
            <li><span class="checkmark">✔</span> Billing & Documentation</li>
            <li><span class="checkmark">✔</span> Commission & Settlements</li>
            <li><span class="checkmark">✔</span> Transaction Alerts (SMS / Email)</li>
            <li><span class="checkmark">✔</span> Customer & Vendor Web Panel</li>
            <li><span class="checkmark">✔</span> Branch / Team Security Policies</li> */}
          </ul>
        </div>
         </div>

         <div class="col-md-6">
         <div class="feature-box">
          <ul class="list-unstyled">
            {/* <li><span class="checkmark">✔</span> Product / Style Bank</li>
            <li><span class="checkmark">✔</span> Sales Enquiry & Quotations</li>
            <li><span class="checkmark">✔</span> Customer Order Management</li>
            <li><span class="checkmark">✔</span> Sampling & Follow-ups</li>
            <li><span class="checkmark">✔</span> Financial Accounting</li>
            <li><span class="checkmark">✔</span> MIS Query Reporting</li>
            <li><span class="checkmark">✔</span> Dashboard & Analytics</li> */}
            <li><span class="checkmark">✔</span> Time & Action Scheduler</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Billing & Documentation</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Commission & Settlements</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Transaction Alerts (SMS / Email)</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Customer & Vendor Web Panel</li>
            <li style={{ marginTop: '11px'}}><span class="checkmark">✔</span> Branch / Team Security Policies</li>
          </ul>
        </div>
         </div>  
         
        </div>

        <div className='row' style={{ marginTop: '87px'}}>
        <div class="col-md-6 ">
        {/* <div> <span className='faslgo'><img src={fas} /></span>FAS</div> */}
        <div className='fassec'>
        <div style={{ marginLeft: '11px'}}><span><img className='faslgo' src={fas} /></span>FAS</div>
        </div>
        <div className='faslorem'>Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus sollicitudin lacus.</div>
         <div className='fasexplore'>Explore</div>
        </div>
        <div class="col-md-6">
        <div className='fassec'>
        <div style={{ marginLeft: '11px'}}><span><img className='faslgo' src={CRM} /></span>CRM</div>
        </div>
        <div className='faslorem'>Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus sollicitudin lacus.</div>
         <div className='fasexplore'>Explore</div>
        </div>
       
        </div>

      </div>
    </div>

   
    {/* <div class="text-center mb-4">
      <h3>Integrated Add-Ons</h3>
    </div>
    <div class="row g-4">
      <div class="col-md-6">
        <div class="addon-box">
          <div class="icon">📊</div>
          <h5 class="mt-3">FAS</h5>
          <p>
            Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus sollicitudin lacus.
          </p>
          <a href="#">Explore →</a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="addon-box">
          <div class="icon">🤝</div>
          <h5 class="mt-3">CRM</h5>
          <p>
            Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus sollicitudin lacus.
          </p>
          <a href="#">Explore →</a>
        </div>
      </div>
    </div> */}

  </div>

  <img src={hashline} className='hashline'/>

   <div className='mission'>We’re on a mission to help <br></br> understand with each concept.</div>
   <div className='missinlLorem'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, <br></br> mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem.</div>

  
  <div className='centralized'>
     <div className='centralizedtxt'>Centralized Dashboard</div> 
     <img src={maindash} className='maindash'/>

  </div>

  <div className='container'>
<div className='row'>
<div class="col-lg-6">
<img src={enquirynew} className=''/>

</div>
<div class="col-lg-6">
  <div className='Quotation'>Enquiry and <br></br>
  Quotation Management:</div>
  <div className='Quotationtxt'>Efficiently manage sales leads with FITTEX MMS. Track customer inquiries and quote statuses with automated delay alerts. Generate dynamic, detailed quotes in minutes using customizable templates, including components, bill of materials, and process flows, streamlining your entire quotation process.</div>
</div>
</div>
</div>




<div className='costingslidersec'>
<div className='container'>
<div className='row'  style={{ marginTop: '41px'}}>
<CostingSlider/>
</div>
</div>
</div>


<div className='container'>
<div className='row'>
<div class="col-lg-6">
<div className='ordertle'>Order Management </div>
  <div className='ordertletxt'>FITTEX MMS allows you to create or import detailed sales orders for bulk and sampling, including colour, size, style, and packaging specifications. Manage multiple styles, delivery locations, and packaging assortments with ease, and drill down into every detail for complete order control.</div>
</div>
<div class="col-lg-6">
<img src={ordermanagement} className=''/>
</div>
</div>
</div>


<div className='tafolowssec costingslidersec'>
  <div className='container'>
     <div className='row'>
       <FoSliderBootstrap/>
     </div>
  </div>
</div>



<div className='shipmentsec'>
  <div className='container'>
     <div className='row'>
       <ShippmentsBootstrap/>
     </div>
  </div>
</div>

<div className='container'>
<div className='row greenbgsec'>
<div className='col-lg-6 businessmanagement'>
<div className='businessmanagetxt'>Business <span style={{ color: 'white'}}>Management</span></div>
<div className='startegytxt'>From stragety to execution, FIT ONE BOX modules helps clients build their next competitive advantage by utilizing the dynamic query features that helps you drill down from the consolidted data to the root transaction levels. Never you will be left out of information to make the right decisions for your business.</div>
</div>
<div className='col-lg-6 businessmanagement'>
<div className='businessmanagetxt'>Customer Support<span style={{ color: 'white'}}>Tickets</span></div>
<div className='startegytxt'>FIT ONE BOX can play a huge role in providing professional customer service with ease. Our Automatic scheduling system allows scheduling the ticket trigger for specific time period and days of the week. This feature allows you to set the parameters within the system to direct the ticket to right agent.</div>
</div>
</div>
</div>

  </div>
  )
}

export default Mms
