import React, { useState } from 'react';
import image1 from './assets/billingshippments1.svg';
import image2 from './assets/billingshippments2.svg';
import image3 from './assets/billingshippments3.svg';
import rightgrey from './assets/costarrowright.svg';
import leftgrey from './assets/costarrowleft.svg';

const ShippmentsBootstrap = () => {
  const images = [image1, image2, image3]; // Array of slide images
  const [currentIndex, setCurrentIndex] = useState(0); // Current slide index

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="container-fluid" style={{ height: '895px' }}>
      {/* Arrows and Title Section */}
      <div className="d-flex align-items-center justify-content-center pt-3">
        <button onClick={prevSlide} className="btn me-5">
          <img src={leftgrey} alt="left-arrow" />
        </button>
        <h2 className="text-black fw-bold" style={{ fontSize: '34px' }}>Billing & Shipments</h2>
        <button onClick={nextSlide} className="btn ms-5">
          <img src={rightgrey} alt="right-arrow" />
        </button>
      </div>

      {/* Conditional Layout: Single or Two Columns */}
      {currentIndex === 0 ? (
        // Single column layout for the first slide
        <div className="mt-4 mx-auto" style={{ maxWidth: '89%' }}>
          <div className="d-flex justify-content-center">
            <img
              src={images[currentIndex]}
              className="img-fluid mt-4"
              style={{ maxWidth: '911px' }}
              alt="slide"
            />
          </div>
          <div
            className="text-center mt-4 mx-auto"
            style={{ fontSize: '22px', maxWidth: '995px' }}
          >
            FITTEX-EXM module automates all export-import documentation, ensuring compliance with
            statutory requirements. Seamlessly integrated with costing for instant analysis, this
            feature simplifies billing and shipments, streamlining the entire process from
            production to delivery.
          </div>
        </div>
      ) : (
        // Two column layout for the other slides
        <div className="row mt-4 mx-auto" style={{ maxWidth: '89%' }}>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={images[currentIndex]} className="img-fluid" alt="slide" />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div
              className="text-dark"
              style={{ fontSize: '22px', maxWidth: '597px', fontFamily: 'Quicksand, sans-serif' }}
            >
              FITTEX-EXM module automates all export-import documentation, ensuring compliance with
              statutory requirements. Seamlessly integrated with costing for instant analysis, this
              feature simplifies billing and shipments, streamlining the entire process from
              production to delivery.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShippmentsBootstrap;