import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import imgNew from './assets/fittexbanner.svg'
import greyline from './assets/greyline.svg'
import mainonebx from './assets/mainonebx.png'
import chartbg from './assets/chartbg.svg'
import fourpointdk from './assets/4pointdk.svg'
import gradientwave from './assets/gradient_shadows.svg'
import tara from './assets/tarasafe.svg'
import dc from './assets/dc.svg'

const LandingPage = () => {
  return (
    <div className="App landingpage">
    <div className='ultimate'>The Ultimate End-to-End <span className='textilebiz'>Textile Biz</span> </div>
    <div className='ultimate'>Automation Solution</div>
    <div className='quicksand-medium scalerfastertxt'>Scale Faster, Operate Smarter, and Seamlessly Integrate every <br></br> aspect of your Apparel & Textile Business.</div>
    <div className='requestdemo'>Request a Demo</div>
    <div className='fittexbanner'>
       <img src={imgNew} className='w-[1620px] h-[720px] mt-[84px]' />
     </div>
     <img src={greyline} className='greyborder' />
      <div className='fittexcaters'>Fittex Caters to <br></br>
      every stage of the Business.</div>
      <div className='manufacturesindustry'>From Manufacturers to Retailers, no matter your role in the Apparel Industry.</div>

<div class="container my-4 ">
 <div class="row boxescaters">

   <div class="col-12 col-sm-6 col-md-4 col-lg-3 box1 txt1">
     <div className='mmstitle'>Merchandising Management</div>
     <div className='mmsdesc'>Gain total control of your Sourcing & <br></br> Buying Agency Operations.</div>
     <div className='explorebtn'>Explore</div>
   </div>

   <div class="col-12 col-sm-6 col-md-4 col-lg-3 box2 txt1">
   <div className='mmstitle'>Factory Business <br></br> Suite</div>
     <div className='mmsdesc'>Integrated Apparel Factory Management <br></br> that works on a 4 Point Planning System.</div>
     <div className='explorebtn'>Explore</div>
   </div>

   <div class="col-12 col-sm-6 col-md-4 col-lg-3 box3 txt1">
   <div className='mmstitle'>Supply Chain <br></br> Management</div>
     <div className='mmsdesc'>Integrated Warehouse & Distribution <br></br> Management linked to Chain Retailing.</div>
     <div className='explorebtn'>Explore</div>
   </div>

   <div class="col-12 col-sm-6 col-md-4 col-lg-3 box4 txt1">
   <div className='mmstitle'>Customer Relation <br></br> & Beyond</div>
     <div className='mmsdesc'>Potential leads to customer conversion with  <br></br> integrated Support & Work Flow System.</div>
     <div className='explorebtn'>Explore</div>
   </div>
 </div>
</div>


<div className='texdxbanner'>
<div className='ultimatetoll'>The Ultimate Tool to Collaborate your <br></br> Apparel Business</div>
<div className='ulimateloremcnt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est <br></br> a, mattis tellus. Sed dignissim, metus nec fringilla. sit amet, consectetur adipiscing elit. Etiam <br></br> eu turpis molestie, dictum est a, mattis tellus.</div>
<div className='exploreblackbtn'>Explore</div>

</div>


<div className='onebxbg' style={{ position: 'relative' }}>
 <div className='container part1'>
  <div className='row' style={{ position: 'relative' }}>
   <div className='col-lg-4'>
     <img src={mainonebx}/>
   </div>
   <div className='col-lg-8'>
     <div className='introducing'>INTRODUCING</div>
     <div className='onebxlogo'>One<span style={{ color: '#17A86D' }}>box</span></div>
     <div className='powerhousetxt'>All-in-One Powerhouse <span style={{ fontFamily: 'RedHatDisplayReqular' }}>for <br></br> Your Apparel Business.</span></div>
     <div className='powerhousepara'>Experience the next level of automation with FITTEX OneBox – our latest yet ultimate version that bundles every essential module into a single, cohesive application. Seamlessly connected and perfectly integrated, OneBox empowers you to manage your entire business from one unified platform like never before.</div>
   </div>
  </div>
 </div>

 <div className='smarttxt'>One <span className='automationtxt'>Smart Software Platform</span>  <br></br> for the entire industry automation needs 
 {/* <div className='container platformlistmain' >
 <div className='row'>
 <div className='col-lg-2'>
    <div className='platformlist'>MANUFACTURING</div>
   </div>
   <div className='col-lg-2'>
   <div className='platformlist'>WAREHOUSING</div>
   </div>  
     <div className='col-lg-2'>
     <div className='platformlist'>Supply CHAIN</div>
   </div>  
     <div className='col-lg-2'>
     <div className='platformlist'>RETAILING</div>
   </div>
   <div className='col-lg-2'>
   <div className='platformlist'>E - COMMERCE</div>
   </div>
   <div className='col-lg-2'>
   <div className='platformlist'>CUSTOMER RELATION</div>
   </div>


 </div>
 </div> */}


 <div className='listes'>
         <span className='listtitle '>MANUFACTURING</span>
         <span className='listsympl'>|</span>
         <span className='listtitle'>WAREHOUSING</span>
         <span className='listsympl'>|</span>
         <span className='listtitle '>SUPPLY CHAIN</span>
         <span className='listsympl'>|</span>
         <span className='listtitle '>RETAILING</span>
         <span className='listsympl'>|</span>
         <span className='listtitle '>E-COMMERCE</span>
         <span className='listsympl'>|</span>
         <span className='listtitle '>CUSTOMER RELATION</span>
       </div>


 </div>


 <div className='readytotransform'>
   <div className='container'>
    <div className='row'>
       <div className='col-lg-6'>
          <div className='Transformtxt'>Ready to Transform <br></br> Your Business?</div>
          <div className='unparalledtxt'>Experience Unparalleled Efficiency,<br></br> Cost Savings and Growth.</div>
       </div>

       <div className='col-lg-6'>
       <img src={chartbg} className='chartbg'/>
       </div>
    </div>
   </div>
 </div>
{/* 
 <div className='fittextengine'>
 <div className='container infiitexengine'>
    <div className='row'>
       <div className='col-lg-4 reverse'>
         
       </div>
       <div className='col-lg-4 Access'>
         
         </div>
         <div className='col-lg-4 Total'>
         
         </div>
    </div>
  </div>
 </div> */}


<div class="container py-5 InsidetheFittex">
<h1 class="text-center mb-4 insidetitle">Inside the Fittex Engine:</h1>
<div class="row boxes">
 {/* <!-- Box 1 --> */}
 <div class="col-lg-4">
   <div class="custom-box">
     <h3 className='enginetxt'>Reverse Integration from Yarn</h3>
     <p className='enginepara'>Fittex offers a unique competitive edge with reverse integration, starting from the yarn stage. Unlike other products, FITTEX ensures seamless tracking and accountability right from the beginning of the production process.</p>
   </div>
 </div>
 {/* <!-- Box 2 --> */}
 <div class="col-lg-4">
   <div class="custom-box">
     <h3 className='enginetxt'>Access Anytime, Anywhere</h3>
     <p className='enginepara'>Fittex's cloud deployment allows you to monitor your factory, warehouse, or shop from anywhere. Stay informed with real-time data and make decisions with just a click, no matter where you are.</p>
   </div>
 </div>
 {/* <!-- Box 3 --> */}
 <div class="col-lg-4">
   <div class="custom-box">
     <h3 className='enginetxt'>Privacy and Security</h3>
     <p className='enginepara'>Fittex prioritises your data privacy. Deployed on your chosen server, we ensure full control and security, with no access to your sensitive information. Your data remains entirely yours, safeguarded with the highest standards.</p>
   </div>
 </div>
 {/* <!-- Box 4 --> */}
 <div class="col-lg-4">
   <div class="custom-box">
     <h3 className='enginetxt'>Advanced Drill-Down Architecture</h3>
     <p className='enginepara'>With FITTEX’s advanced drill-down system, track everything from raw materials to invoices with just a few clicks. Access all data in an organized, streamlined way, ensuring you never miss vital information in your business operations.</p>
   </div>
 </div>
 {/* <!-- Box 5 --> */}
 <div class="col-lg-4">
   <div class="custom-box">
     <h3 className='enginetxt'>End-to-End Solution</h3>
     <p className='enginepara'>Fittex covers every aspect of your business, from pre-yarn inquiries to retail. Each module seamlessly integrates, creating a continuous workflow that ensures smooth operations across all stages of your apparel business.</p>
   </div>
 </div>
 {/* <!-- Box 6 --> */}
 <div class="col-lg-4">
   <div class="custom-box">
     <h3 className='enginetxt'>Multilingual Ready</h3>
     <p className='enginepara'>Fittex is designed for the global market with multilingual capabilities. Import any language script, and the application can be transformed to suit your needs, enabling seamless use in any language, anywhere in the world.</p>
   </div>
 </div>
</div>
</div>


<div className='fourplan'>
<div className='row'>
<div class="col-lg-7">
<img src={fourpointdk}  className='fourpointdk'/>

</div>
<div class="col-lg-5">
<div className='garmenttxt'>Provide Complete Control to <br></br> your <span className='garmentbusinesstxt'>Garment Business.</span></div>
<div className='quicksandtxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan,</div>
</div>
</div>
</div>





</div>

<div className='whatfilter'>What are the <br></br>
customers says about fittex. </div>

<div className='conatiner testimnials'>
<div className='row'>
<div className='col-lg-12'>
<div className="relative">
     <img src={gradientwave} className='mx-auto' />
     <a href="#" className='absolute '>
         More Stories &gt;&gt;
     </a>
 </div>
</div>
</div>
</div>


<div className='container'>
<div className='row'>
<div className='col-lg-6 tara'>
<img src={tara} className='taralogo'/>
<div className='customertxt'>Mr. Vivek Lohia</div>
<div className='director'>Director</div>
<div className='dumytxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis <br></br> molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla<br></br> accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.<br></br> Maecenas eget condimentum velit ccumsan, risus<br></br> sem sollicitudin lacus, ut interdum tellus elit. </div>
</div>
<div className='col-lg-6 dc'>
<img src={dc} className='taralogo'/>
<div className='customertxt'>Mr. Vivek Lohia</div>
         <div className='director'>Director</div>
         <div className='dumytxt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis <br></br> molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla<br></br> accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.<br></br> Maecenas eget condimentum velit ccumsan, risus<br></br> sem sollicitudin lacus, ut interdum tellus elit. </div>
</div>
</div>
</div>



<div className='letsbeginstart'>
<div className='letbegintxt'>Let Bigin be a part of <br></br> your next <span style={{ color: 'white',fontFamily: 'RedHatDisplayBold' }}>big growth Story</span></div>

</div>

<div>
<div className='demobtn' style={{ display: 'flex' }}>
<div className='bookbtn'>Book a Demo</div>
<div className='bookbtn1'>See Pricing</div>
</div>
</div>

{/* 
<div>
<Footer/>
</div> */}



 </div>
  );
};

export default LandingPage;