import React, { useState } from 'react';
import image1 from './assets/costing1.svg';
import image2 from './assets/costingwork2.svg';
import image3 from './assets/costingwork3.svg';
import image4 from './assets/costingwork4.svg';
import leftgrey from './assets/costarrowleft.svg';
import rightgrey from './assets/costarrowright.svg'; // Assuming you have a right arrow image

const CostingSlider = () => {
  const images = [image1, image2, image3, image4];
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="container-fluid">
      {/* Arrows and Title Section */}
      <div className="d-flex justify-content-center align-items-center py-4">
        <button onClick={prevSlide} className="btn me-5">
          <img src={leftgrey} alt="left-arrow" />
        </button>
        <h2 className="text-dark fw-bold fs-4">Costing & Working</h2>
        <button onClick={nextSlide} className="btn ms-5">
          <img src={rightgrey} alt="right-arrow" />
        </button>
      </div>

      {/* Slider Section */}
      <div className="row w-100 mx-auto mt-4">
        {/* Left Side (Image) */}
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img
            src={images[currentIndex]}
            alt="costing-slide"
            className="img-fluid" // Bootstrap class for responsive images
            style={{ maxHeight: '711px' }}
          />
        </div>

        {/* Right Side (Static Content) */}
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <div
            className="text-dark"
            style={{
              fontSize: '22px',
              fontFamily: 'Quicksand, sans-serif',
              maxWidth: '597px',
            }}
          >
            Efficiently manage sales leads with FITTEX MMS. Track customer inquiries and quote statuses with automated delay alerts. Generate dynamic, detailed quotes in minutes using customizable templates, including components, bill of materials, and process flows, streamlining your entire quotation process.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostingSlider;